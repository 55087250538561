// const BASE_URL = 'https://kooo-recycle-api.rcs-mm.com/api'; 
const BASE_URL = 'https://shwe-mann-thein-api.rcs-mm.com/api'; 


const HEADERS = ()=>{
    const token = JSON.parse(localStorage.getItem('recycleAppAuth')).token;
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }
};

export { BASE_URL,HEADERS };