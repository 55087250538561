import React from 'react'

const VoucherHeader = () => {
  return (
    <div>
        <p className="text-center fw-bold" style={{ fontSize: "22px"}}>ရွှေမန်းသိန်းကော်ခုတ်စက်နှင့်ကော်မျိုးစုံရောင်းဝယ်ရေး</p>
        <p className="text-center">စဉ့်ကိုင်မြို့နယ်၊တအုံကွန်ပျူတာလမ်းပေါ်</p>
        <p className="text-center">ဖုန်းနံပါတ် - ၀၉-၄၀၂၇၀၉၃၃၅၊ ၀၉-၇၉၆၈၀၂၄၄၅</p>
    </div>
  )
}

export default VoucherHeader
